

import {Component, Prop, VModel, Vue} from "vue-property-decorator";

@Component
export default class DialogScroll extends Vue{

  @VModel({ type: Boolean, default: false }) active!: boolean;
  @Prop({ type: String, default: '' }) title!: string

}
