import { render, staticRenderFns } from "./AvatarDetails.vue?vue&type=template&id=5e1958be&scoped=true&"
import script from "./AvatarDetails.vue?vue&type=script&lang=ts&"
export * from "./AvatarDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e1958be",
  null
  
)

export default component.exports