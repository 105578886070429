

import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class CardPoker extends Vue {

  $refs!: {
    card: HTMLElement;
  }

  @Prop({ type: String, default: '' }) dataImage!: string;
  @Prop({ type: Boolean, default: false }) selected!: boolean;

  width = 0;
  height = 0;
  mouseX = 0;
  mouseY = 0;
  mouseLeaveDelay: any = null;

  get mousePX() {
    return this.mouseX / this.width;
  }

  get mousePY() {
    return this.mouseY / this.height;
  }

  get cardStyle() {
    const rX = this.mousePX * 30;
    const rY = this.mousePY * -30;
    return {
      transform: `rotateY(${rX}deg) rotateX(${rY}deg)`
    };
  }

  get cardBgTransform() {
    const tX = this.mousePX * -40;
    const tY = this.mousePY * -40;
    return {
      transform: `translateX(${tX}px) translateY(${tY}px)`
    }
  }

  get cardBgImage() {
    return {
      backgroundImage: `url(${this.dataImage})`
    }
  }

  mounted(): void {
    this.width = this.$refs.card.offsetWidth;
    this.height = this.$refs.card.offsetHeight;
  }

  handleMouseMove(e: any) {
    this.mouseX = e.pageX - this.$refs.card.offsetLeft - this.width/2;
    this.mouseY = e.pageY - this.$refs.card.offsetTop - this.height/2;
  }

  handleMouseEnter() {
    clearTimeout(this.mouseLeaveDelay);
  }

  handleMouseLeave() {
    this.mouseLeaveDelay = setTimeout(()=>{
      this.mouseX = 0;
      this.mouseY = 0;
    }, 1000);
  }

}
