

import {Component, Vue} from "vue-property-decorator";
import {Room} from "@/_models/Room";
import {RoomService} from "@/_services/roomService";
import ButtonCard from "@/components/ButtonCard.vue";
import {CreateRoomRequest} from "@/_models/requests/CreateRoomRequest";
@Component({
  components: {ButtonCard}
})
export default class CreateRoom extends Vue{

  roomRequest = new CreateRoomRequest();

  async createRoom(): Promise<void> {
    const room: Room = await RoomService.createRoom(this.roomRequest);
    if (room.id) {
      await this.$router.push(`/room/${room.id}`);
    }
  }

}
