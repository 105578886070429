import firebase from "firebase/compat/app";

const firebaseConfig = {
    apiKey: "AIzaSyBi3-457tlS08W8cUtyKclPUZc641EF3iA",
    authDomain: "photo-en-nord.firebaseapp.com",
    databaseURL: "https://photo-en-nord-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "photo-en-nord",
    storageBucket: "photo-en-nord.appspot.com",
    messagingSenderId: "613993522052",
    appId: "1:613993522052:web:d99f253cf1580ca09eb3d1",
    measurementId: "G-X40TD55816"
};

firebase.initializeApp(firebaseConfig);
