import Vuex from 'vuex'
import Vue from "vue";
import AuthModule from "@/_store/auth";


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        AuthModule
    }
});
