
import {Component, Vue} from "vue-property-decorator";
import {RoomService} from "@/_services/roomService";
import {Room} from "@/_models/Room";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import {UserDetails} from "@/_models/UserDetails";
import {UserService} from "@/_services/userService";
import DialogScroll from "@/components/dialogs/DialogScroll.vue";
import DialogPrivacy from "@/components/dialogs/DialogPrivacy.vue";
import DialogTerms from "@/components/dialogs/DialogTerms.vue";
import CreateRoom from "@/components/forms/CreateRoom.vue";
import {namespace} from "vuex-class";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import {User} from "@/_models/User";
import AvatarDetails from "@/components/AvatarDetails.vue";
import UserMenu from "@/components/UserMenu.vue";

const authModule = namespace('AuthModule');

@Component({
  components: {UserMenu, AvatarDetails, CreateRoom, DialogTerms, DialogPrivacy, DialogScroll, LoginDialog, KinesisContainer, KinesisElement}
})
export default class House extends Vue {

  @authModule.Action
  retrieveMe!: () => Promise<void>

  @authModule.Getter
  getCurrentUser!: User;

  joinMode = false;
  createMode = false;
  idRoom = '';
  active = false;
  activePrivacy = false;
  activeTerms = false

  join(): void {
    this.$router.push(`/room/${this.idRoom}`)
  }

  mounted(): void {
    this.retrieveMe();
  }

}
