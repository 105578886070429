import {Message} from "@/_models/Message";
import { getDatabase, ref, set, get, remove } from "firebase/database";
import axios from "axios";
import {Room} from "@/_models/Room";
import {User} from "@/_models/User";
import {UserRoom} from "@/_models/UserRoom";
import {Api} from "@/_services/api";
import {CreateRoomRequest} from "@/_models/requests/CreateRoomRequest";

export class RoomService {

    static async createRoom(room: CreateRoomRequest): Promise<Room> {
        const response = await axios.post<Room>(`${Api.URL}/api/room`, room, { withCredentials: true });
        return response.data;
    }

    static async getRoomById(roomId: string): Promise<Room> {
        const response = await axios.get<Room>(`${Api.URL}/api/room/${roomId}`, { withCredentials: true });
        return response.data;
    }

    static async joinRoomById(roomId: string, role: number): Promise<User> {
        const response = await axios.post<User>(`${Api.URL}/api/room/${roomId}/join`, {role}, { withCredentials: true });
        return response.data;
    }

    static async sendImageInRoomId(roomId: string, image: string): Promise<void> {
        await axios.post(`${Api.URL}/api/room/${roomId}/image`, { image }, { withCredentials: true });
    }

    static async sendPokerValueInRoomId(roomId: string, poker: string): Promise<void> {
        await axios.post(`${Api.URL}/api/room/${roomId}/poker`, { poker }, { withCredentials: true });
    }

    static async getMeByRoomId(roomId: string): Promise<UserRoom> {
        const response = await axios.get(`${Api.URL}/api/room/${roomId}/me`, { withCredentials: true });
        return response.data;
    }

    static async changeRoleByRooId(roomId: string, role: number): Promise<void> {
        await axios.post(`${Api.URL}/api/room/${roomId}/changeRole`, {role}, { withCredentials: true });
    }

}
