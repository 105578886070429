import {UserDetails} from "@/_models/UserDetails";
import axios from "axios";
import {Api} from "@/_services/api";
import {User} from "@/_models/User";

export class UserService {

    static async me(): Promise<User> {
        const response = await axios.get<User>(`${Api.URL}/api/user/me`, {
            withCredentials: true
        });
        return response.data;
    }

}
