

import {Component, Vue} from "vue-property-decorator";
import AvatarDetails from "@/components/AvatarDetails.vue";
import {namespace} from "vuex-class";

const authModule = namespace('AuthModule');

@Component({
  components: {AvatarDetails}
})
export default class UserMenu extends Vue {

  @authModule.Action
  logout!: () => Promise<void>

  async callLogout(): Promise<void> {
    await this.logout();
    await this.$router.push('/');
  }

}
