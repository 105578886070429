

import {Component, Prop, VModel, Vue} from "vue-property-decorator";
@Component
export default class FullscreenPreviewDialog extends Vue {

  @VModel({ type: Boolean }) active!: boolean;

  @Prop({ type: String, default: '' }) image!: string;
  @Prop({ type: String, default: '' }) username!: string;

}
