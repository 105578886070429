

import {Component, Prop, Vue} from "vue-property-decorator";
import {UserDetails} from "@/_models/UserDetails";
import AvatarDetails from "@/components/AvatarDetails.vue";
import {UserRoom} from "@/_models/UserRoom";
@Component({
  components: {AvatarDetails}
})
export default class UserPoker extends Vue {

  @Prop({ type: Object, default: () => {} }) user!: UserRoom;

}
