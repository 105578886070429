
import {Component, VModel, Vue} from "vue-property-decorator";
import DialogScroll from "@/components/dialogs/DialogScroll.vue";

@Component({
  components: {DialogScroll}
})
export default class DialogPrivacy extends Vue{

  @VModel({ type: Boolean, default: false }) active!: boolean;


}
