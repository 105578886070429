import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/_store';
import '@/plugins/socket';
import vuesax from 'vuesax'
import axios from "axios";

import 'boxicons/css/boxicons.min.css'
import '@/assets/styles/global.scss'
import 'vue-advanced-cropper/dist/style.css';

import './firebase';

import 'vuesax/dist/vuesax.css' //Vuesax styles

Vue.use(vuesax, {
  // options here
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
