import axios from "axios";
import {Api} from "@/_services/api";

export class ImagineResponse {
    image = '';
}

export class OpenaiService {

    static async imaginePicture(prompt: string): Promise<string> {
        const response = await axios.post<ImagineResponse>(`${Api.URL}/api/openai/imagine`, { prompt: prompt }, { withCredentials: true });
        if (response.data && response.data.image) {
            return response.data.image
        }
        return '';
    }

}
