import {PokerInstance} from "@/_models/PokerInstance";
import axios from "axios";
import {Api} from "@/_services/api";

export class PokerInstanceService {

    static async getPokerInstanceByRoomId(roomId: string): Promise<PokerInstance> {
        const response = await axios.get(`${Api.URL}/api/poker/room/${roomId}`, { withCredentials: true });
        return response.data;
    }

}
