
import {Component, Vue, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";

const authModule = namespace('AuthModule');

@Component
export default class App extends Vue {

  @authModule.Action
  retrieveMe!: () => Promise<void>

  darkMode = true;

  mounted(): void {
    this.retrieveMe();
    document.querySelector('body')?.classList.add('darken');
    document.querySelector('html')?.classList.add('darken');
  }

  @Watch('darkMode')
  watcherDarkMode() {
    if (this.darkMode) {
      document.querySelector('body')?.classList.add('darken');
      document.querySelector('html')?.classList.add('darken');
      document.querySelector('body')?.classList.remove('lighten');
      document.querySelector('html')?.classList.remove('lighten');
    } else {
      document.querySelector('body')?.classList.remove('darken');
      document.querySelector('html')?.classList.remove('darken');
      document.querySelector('body')?.classList.add('lighten');
      document.querySelector('html')?.classList.add('lighten');
    }
  }

}
