

import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";
import {AuthService} from "@/_services/authService";
import {namespace} from "vuex-class";
import {LoginRequest} from "@/_models/requests/LoginRequest";

const AuthModule = namespace('AuthModule');

@Component
export default class LoginDialog extends Vue {

  @AuthModule.Action
  login!: (loginRequest: LoginRequest) => Promise<void>

  @VModel({ type: Boolean, default: false }) active!: boolean;
  @Prop({ type: String, default: '' }) subtitle!: string;
  @Prop({ type: String, default: '' }) titleLogin!: string;
  @Prop({ type: String, default: '' }) titleSignup!: string;
  @Prop({ type: Boolean, default: false }) closable!: boolean;

  username = '';
  password = '';
  confirmPassword = '';
  errorSignup = false;
  errorLogin = false;
  check = false;
  signupMode = false;

  get disableButton(): boolean {
    if (this.signupMode) {
      return !this.username || this.password.length < 6 || this.password !== this.confirmPassword || !this.check;
    }
    return false;
  }

  callLogin(): void {
    this.login({username: this.username, password: this.password}).then(() => {
      this.emitSuccessLogin();
      this.resetForm();
      this.resetError();
    }).catch(() => {
      this.errorLogin = true;
    });
  }

  async signup(): Promise<void> {
    const success: boolean = await AuthService.signup(this.username, this.password);
    if (success) {
      this.signupMode = false;
      this.resetForm();
      this.resetError();
    } else {
      this.errorSignup = true;
      this.resetForm();
    }
  }

  resetForm(): void {
    this.username = '';
    this.password = '';
    this.confirmPassword = '';
  }

  resetError(): void {
    this.errorLogin = false;
    this.errorSignup = false;
  }

  changeMode(): void {
    this.signupMode = !this.signupMode;
    this.resetError();
    this.resetForm();
  }

  @Emit('successLogin')
  emitSuccessLogin(): void {}

}
