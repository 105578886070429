
import {Component, Vue} from "vue-property-decorator";
import {Socket} from "vue-socket.io-extended";
import {Room} from "@/_models/Room";
import {RoomService} from "@/_services/roomService";
import {User} from "@/_models/User";
import {Message} from "@/_models/Message";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import {AuthService} from "@/_services/authService";
import UserMood from "@/components/UserMood.vue";
import {UserRoom} from "@/_models/UserRoom";
import {namespace} from "vuex-class";
import Icebreaker from "@/components/games/Icebreaker.vue";
import AvatarDetails from "@/components/AvatarDetails.vue";
import PokerPlanning from "@/components/games/PokerPlanning.vue";
import ButtonCard from "@/components/ButtonCard.vue";
import UserMenu from "@/components/UserMenu.vue";

const authModule = namespace('AuthModule');

@Component({
  components: {UserMenu, ButtonCard, PokerPlanning, AvatarDetails, Icebreaker, UserMood, LoginDialog}
})
export default class Lobby extends Vue{

  @authModule.Getter
  getCurrentUser!: User;

  @Socket()
  change_role(userId: string, role: number): void {
    this.users.map((u: UserRoom) => {
      if (u.user.id === userId) {
        u.role = role;
      }
    });
  }

  @Socket()
  new_user(user: UserRoom) {
    const index = this.users.findIndex((u) => user.user.id === u.user.id);
    if (index < 0) {
      this.users.push(user);
    }
  }

  test: UserRoom[] = [new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),new UserRoom(),]

  @Socket()
  quit_user(user: User) {
    this.users = this.users.filter((u) => user.id !== u.user.id);
  }

  @Socket()
  message(msg: Message) {
    this.messages.push(msg);
  }

  messages: Message[] = [];
  messagePrompt = '';
  room: Room = new Room();
  active = false;
  activeChooseRole = false;
  activeChangeRole = false;
  currentUser: UserRoom = new UserRoom();
  users: UserRoom[] = [];

  get getOtherPlayers(): UserRoom[] {
    return this.users.filter((user: UserRoom) => user.role === 0 && user.user.id !== this.currentUser.user.id);
  }

  get getSpectators(): UserRoom[] {
    return this.users.filter((u: UserRoom) => u.role === 1);
  }

  mounted(): void {
    this.checkAuth();
  }

  changeRole(role: number): void {
    RoomService.changeRoleByRooId(this.getId, role)
        .then(() => {
          this.currentUser.role = role;
          this.$socket.client.emit('change_role', this.getId, role);
        });
    this.activeChangeRole = false;
  }

  shareRoom(): void {
    this.$vs.notification({
      color: '#141417',
      position: 'top-center',
      classNotification: 'tooltip-text',
      buttonClose: false,
      text: 'The share information was copied ',
    });
    navigator.clipboard.writeText(`Join the "${this.room.name}" room on Icebreakia!\nurl: ${window.location.href}\nId of the room: ${this.room.id}`);
  }

  async checkAuth(): Promise<void> {
    const success: boolean = await AuthService.checkAuth();
    if (success) {
      await this.join();
    } else {
      this.active = true;
    }
  }

  async getRoom(): Promise<void> {
    try {
      this.room = await RoomService.getRoomById(this.getId);
      RoomService.getMeByRoomId(this.getId).then((user: UserRoom) => {
        if (!user || user.role === undefined || user.role === null) {
          this.activeChooseRole = true;
          return;
        }
        this.currentUser = user;
        this.joinRoom(this.currentUser.role!);
      }).catch(() => this.activeChooseRole = true);
    } catch (e) {
      await this.$router.push('/');
    }
  }

  async joinRoom(role: number): Promise<void> {
    this.activeChooseRole = false;
    await RoomService.joinRoomById(this.getId, role);
    if (!this.currentUser?.user?.id) {
      this.currentUser = await RoomService.getMeByRoomId(this.getId);
    }
    this.$socket.client.emit('join_room', this.getId, this.currentUser.user.id);
  }

  async join(): Promise<void> {
    this.active = false;
    await this.getRoom();
  }

  leaveRoom(): void {
    this.$socket.client.emit('leave_room', this.room.id);
  }

  get getId(): string {
    return this.$route.params.id;
  }

  async sendMessage(): Promise<void> {
    this.$socket.client.emit('send_message', this.getId, this.messagePrompt);
    this.messagePrompt = '';
  }

  beforeDestroy(): void {
    this.leaveRoom();
  }

}
