import {Image} from "@/_models/Image";

export class Room {
    id?: string;
    name = '';
    type?: number;
    createdAt = '';
    updatedAt = '';
    userRoom?: Image;
}
