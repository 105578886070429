

import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ButtonCard extends Vue{

  @Prop({ type: String, default: '' }) image!: string;
  @Prop({ type: String, default: '' }) text!: string;
  @Prop({ type: Boolean, default: '' }) selected!: boolean;

}
