

import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";
import {User} from "@/_models/User";
import {OpenaiService} from "@/_services/openaiService";
import {RoomService} from "@/_services/roomService";
import FullscreenPreviewDialog from "@/components/dialogs/FullscreenPreviewDialog.vue";
@Component({
  components: {FullscreenPreviewDialog}
})
export default class UserMood extends Vue {

  @VModel({ type: String, default: '' }) urlImage!: string;

  @Prop({ type: Object, default: () => {} }) user!: User;
  @Prop({ type: Boolean, default: false }) forMe!: boolean;

  prompt = '';
  loadingImagine = false;
  openFullscreen = false;

  async imagine(): Promise<void> {
    if (this.prompt) {
      this.loadingImagine = true;
      this.urlImage = await OpenaiService.imaginePicture(this.prompt);
    }
    this.loadingImagine = false;
  }

  async send(): Promise<void> {
    if (this.urlImage) {
      await RoomService.sendImageInRoomId(this.$route.params.id, this.urlImage);
      this.$socket.client.emit('send_image', this.$route.params.id, this.urlImage);
    }
  }

}
