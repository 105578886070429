import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import House from "@/views/House.vue";
import Lobby from "@/views/Lobby.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'house',
    component: House
  },
  {
    path: '/room/:id',
    name: 'room',
    component: Lobby
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
