
import {Component, Prop, Vue} from "vue-property-decorator";
import UserMood from "@/components/UserMood.vue";
import {UserDetails} from "@/_models/UserDetails";
import {UserRoom} from "@/_models/UserRoom";
import {Socket} from "vue-socket.io-extended";

export class ImageSocket {
  username = '';
  image = '';
}

@Component({
  components: {UserMood}
})
export default class Icebreaker extends Vue {

  @Socket()
  image(image: ImageSocket): void {
    this.otherUsers.map((userRoom) => {
      if (userRoom.user.username === image.username) {
        userRoom.image = image.image;
      }
    })
  }

  @Prop({ type: Object, default: () => {} }) currentUser?: UserRoom;
  @Prop({ type: Array, default: () => [] }) otherUsers!: UserRoom[];

}
