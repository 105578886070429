

import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {UserRoom} from "@/_models/UserRoom";
import {Socket} from "vue-socket.io-extended";
import {PokerInstance} from "@/_models/PokerInstance";
import {PokerInstanceService} from "@/_services/pokerInstanceService";

@Component
export default class PokerTable extends Vue {

  @Socket()
  reset_poker(): void {
    this.reset();
  }

  @Socket()
  decount_poker(): void {
    this.decount();
  }

  async mounted(): Promise<void> {
    const pokerInstance: PokerInstance = await PokerInstanceService.getPokerInstanceByRoomId(this.$route.params.id);
    if (pokerInstance) {
      this.resultMode = pokerInstance.status === 1;
    }
  }

  @Prop({ type: Array, default: () => [] }) players!: UserRoom[];
  @Prop({ type: Object, default: () => {} }) currentUser!: UserRoom;


  player_playing = 0;

  count = 3;
  decountMode = false;
  resultMode = false;

  emitResetSocket(): void {
    this.$socket.client.emit('reset_poker', this.$route.params.id);
  }

  emitDecountSocket(): void {
    this.$socket.client.emit('decount_poker', this.$route.params.id);
  }

  decount(): void {
    this.decountMode = true;
    const interval = setInterval(() => {
      if (this.count === 0) {
        this.resultMode = true;
        this.decountMode = false;
        clearInterval(interval);
        return;
      }
      this.count--;
    }, 1000);
  }

  @Emit()
  reset(): void {
    this.decountMode = false;
    this.resultMode = false;
    this.count = 3;
  }

}
