import Vue from "vue";
import axios from "axios";
import {User} from "@/_models/User";
import {UserRoom} from "@/_models/UserRoom";
import {UserDetails} from "@/_models/UserDetails";
import {Api} from "@/_services/api";
import {LoginRequest} from "@/_models/requests/LoginRequest";

export class AuthService {

        static async signup(username: string, password: string): Promise<boolean> {
            return axios.post(`${Api.URL}/api/auth/register`, {username, password}).catch(() => false).then((data) => {
                return !!data;
            });
        }

        static async login(loginRequest: LoginRequest): Promise<User> {
            const response = await axios.post<User>(`${Api.URL}/api/auth/login`, loginRequest, { withCredentials: true });
            return response.data;
        }

        static async logout(): Promise<void> {
            await axios.post(`${Api.URL}/api/auth/logout`, null, { withCredentials: true });
        }

        static async checkAuth(): Promise<boolean> {
            return axios.get(`${Api.URL}/api/auth`, { withCredentials: true }).then(data => !!data).catch(() => false);
        }

}
