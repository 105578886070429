import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {UserDetails} from "@/_models/UserDetails";
import {AuthService} from "@/_services/authService";
import {LoginRequest} from "@/_models/requests/LoginRequest";
import {User} from "@/_models/User";
import {UserService} from "@/_services/userService";

@Module({ namespaced: true })
class AuthModule extends VuexModule {

    public currentUser: User = new User();

    @Mutation
    setCurrentUser(user: User): void {
        this.currentUser = user;
    }

    @Mutation
    resetCurrentUser(): void {
        this.currentUser = new User();
    }

    @Action
    async retrieveMe(): Promise<void> {
        const user = await UserService.me();
        this.context.commit('setCurrentUser', user);
    }

    @Action
    async logout(): Promise<void> {
        await AuthService.logout();
        this.context.commit('resetCurrentUser');
    }

    @Action
    async login(loginRequest: LoginRequest): Promise<void> {
        const user: User = await AuthService.login(loginRequest);
        if (user) {
            this.context.commit('setCurrentUser', user);
        }
    }

    get getCurrentUser(): User {
        return this.currentUser;
    }
}
export default AuthModule;
