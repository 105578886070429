

import {Component, Prop, Vue} from "vue-property-decorator";
import {UserDetails} from "@/_models/UserDetails";
import CardPoker from "@/components/CardPoker.vue";
import UserPoker from "@/components/UserPoker.vue";
import {UserRoom} from "@/_models/UserRoom";
import PokerTable from "@/components/PokerTable.vue";
import ButtonCard from "@/components/ButtonCard.vue";
import {RoomService} from "@/_services/roomService";
import {Socket} from "vue-socket.io-extended";

export class PokerSocket {
  username = '';
  poker = '';
}

export class PokerCardDetails {
  title = '';
  description = '';
}

@Component({
  components: {ButtonCard, PokerTable, UserPoker, CardPoker}
})
export default class PokerPlanning extends Vue {

  @Socket()
  poker(poker: PokerSocket): void {
    this.otherUsers.map((userRoom) => {
      if (userRoom.user.username === poker.username) {
        userRoom.poker = poker.poker;
      }
    });
  }

  @Prop({ type: Object, default: () => {} }) currentUser?: UserRoom | null;
  @Prop({ type: Array, default: () => [] }) otherUsers!: UserRoom[];

  fibonacci: PokerCardDetails[] = [
      {
        title: '0',
        description: 'Out of scope'
      },
    {
      title: '1',
      description: 'Very fast'
    },
    {
      title: '2',
      description: 'Very simple'
    },
    {
      title: '3',
      description: 'Simple',
    },
    {
      title: '5',
      description: 'Normal',
    },
    {
      title: '8',
      description: 'Difficult'
    },
    {
      title: '13',
      description: 'Very difficult'
    },
    {
      title: '?',
      description: 'Not understand'
    },
    {
      title: '☕️',
      description: 'Coffee break'
    }]

  async sendPokerValue(value: string): Promise<void> {
    if (this.currentUser) {
      this.currentUser.poker = value;
      await RoomService.sendPokerValueInRoomId(this.$route.params.id, value);
      this.$socket.client.emit('send_poker', this.$route.params.id, value);
    }

  }

}
