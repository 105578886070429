import {Image} from "@/_models/Image";

export class User {
    id?: string;
    username = '';
    image = '';
    createdAt = '';
    updatedAt = '';
    roomId?: string;
}
