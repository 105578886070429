

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {User} from "@/_models/User";

const authModule = namespace('AuthModule');

@Component
export default class AvatarDetails extends Vue{

  @authModule.Getter
  getCurrentUser!: User;

  @Prop({ type: Boolean, default: false }) me!: boolean;
  @Prop({ type: Boolean, default: false }) column!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;
  @Prop({ type: Object, default: () => {} }) userProp!: User;

  user: User = new User();

  mounted(): void {
    this.setUser();
  }

  setUser(): void {
    if (this.me) {
      this.user = this.getCurrentUser;
    } else {
      this.user = this.userProp;
    }
  }

  @Watch('getCurrentUser')
  watchCurrentUser(): void {
    this.setUser();
  }

}
